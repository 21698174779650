import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { AuthStore } from '../auth.store';
import { from, Observable, switchMap } from 'rxjs';
import { AppSettings } from '@twist/core';
import { Logger } from '@twist/logging';

@Injectable()
export class AddAccessTokenInterceptor implements HttpInterceptor {

  private _appSettings = inject(AppSettings);
  private _authStore = inject(AuthStore);
  private _logger = inject(Logger);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (this.isApiUrl(request) && !(request.headers.get('skip-tokens') == 'true')) {

      return from(this._authStore.isAuthenticated())
        .pipe(
          switchMap(authenticated => {
            if (authenticated) {
              this._logger.debug('[AddAccessTokenInterceptor] - 🎫', request.url);
              const token = this._authStore.accessToken();
              const headers = request.headers
                .set('Authorization', 'Bearer ' + token)
                .append('Content-Type', 'application/json');
              const requestClone = request.clone({
                headers
              });
              return next.handle(requestClone);
            }
            this._logger.debug('[AddAccessTokenInterceptor] - Ignored - not authenticated', request.url);
            return next.handle(request);
          })
        );
    } else {
      this._logger.debug('[AddAccessTokenInterceptor] - ignored ', request.url);
      return next.handle(request);
    }
  }

  private isApiUrl(request: HttpRequest<any>): boolean {
    return request.url.startsWith(this._appSettings.environment.backboneUrl) && request.url.indexOf('refresh-token') < 0;
  }

}

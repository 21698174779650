import { patchState, signalState } from '@ngrx/signals';
import { parseJwtAccesToken } from './jwt-token';
import { computed, effect, inject, Injectable } from '@angular/core';
import { filter, lastValueFrom, of, switchMap, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AppSettings } from '@twist/core';

export type UserData = {
  id: string;
  email: string;
  name: string;
  roles: string[];
}

export type AuthState = {
  idToken: string;
  accessToken: string;
  hasValidTokens: boolean;
  user: UserData;
  initialized: boolean;
}

export const initialAuthState: AuthState = {
  user: {
    id: 'f',
    email: 's',
    name: 'f',
    roles: []
  },
  idToken: '',
  accessToken: '',
  hasValidTokens: false,
  initialized: false
};

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  //#authenticationBackboneService = inject(AuthenticationBackboneService);
  //#storageHelper = inject(LocalStorageHelper);
  #router = inject(Router);

  private _oAuthService = inject(OAuthService);
  private _appSettings = inject(AppSettings);

  #state = signalState<AuthState>(initialAuthState);

  LOCAL_STORAGE_KEY = 'tw_rt';

  tryExtractTokenDataToState = () => {
    if (this._oAuthService.hasValidAccessToken() && this._oAuthService.hasValidIdToken()) {
      const idToken = this._oAuthService.getIdToken();
      const accessToken = this._oAuthService.getAccessToken();
      const idTokenData = parseJwtAccesToken(idToken);
      const accessTokenData = parseJwtAccesToken(accessToken);
      patchState(this.#state, {
        accessToken,
        idToken,
        hasValidTokens: true,
        user: {
          id: idTokenData.nameid,
          name: idTokenData.name || idTokenData.given_name || idTokenData.email || 'sdfsdfsdf',
          email: idTokenData.email,
          roles: accessTokenData.roles
        },
        initialized: true
      });
    } else {
      patchState(this.#state, initialAuthState);
    }
  };

  initialize = async () => {
    // check for a refresh token in local storage
    console.info('[AuthStore] - initializing...');
    this._oAuthService.configure(this.getOAuthConfig());

    this.tryExtractTokenDataToState();

    this._oAuthService.events.subscribe((event) => {
      console.info('[AuthStore] - initializing... - EVENT', event);
      this.tryExtractTokenDataToState();
    });

    if (!this.#state.hasValidTokens())
      await this._oAuthService.loadDiscoveryDocumentAndTryLogin();

    if (!this.#state.hasValidTokens())
      this._oAuthService.initCodeFlow('aditionalstate', { test: 'ok', organization: 'twist' });

    //console.info('[AuthStore] - access token...', this._oAuthService.hasValidAccessToken(), this._oAuthService.getAccessToken());
    //console.info('[AuthStore] - id token...', this._oAuthService.hasValidIdToken(), this._oAuthService.getIdToken());
  };

  getOAuthConfig = (): AuthConfig => {
    return {
      issuer: this._appSettings.environment.ciamUrl,
      redirectUri: window.location.origin + '/dashboard',
      clientId: 'twist:portal',
      //dummyClientSecret: '49C1A7E1-0C79-4A89-A3D6-A37998FB86B0',
      responseType: 'code',
      scope: 'openid profile email',
      showDebugInformation: true,
      requireHttps: this._appSettings.environment.production,
      logoutUrl: window.location.origin + '/signout-oidc',
      postLogoutRedirectUri: window.location.origin + '/signout-oidc',
      // Optionally configure the silent refresh
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      useSilentRefresh: true // Needed for Code Flow to suggest using iframe-based refreshes
    };
  };

  isAuthenticatedSignal = computed(() => {
    //console.log('signalr isAuthenticatedSignal', this.#state.hasValidTokens());
    return this.#state.hasValidTokens();
  });

  private initializedObservable = toObservable(this.#state.initialized);

  isAuthenticated = (): Promise<boolean> => {

    // console.log('isAuthenticated', 'start');
    return new Promise<boolean>((resolve, reject) => {
      this.initializedObservable.pipe(
        filter(initialized => initialized),
        tap(initialized => {
          console.log('isAuthenticated -id', initialized, `'${this.#state.idToken()}'`);
          console.log('isAuthenticated -access', initialized, `'${this.#state.accessToken()}'`);
          resolve(this.#state.idToken() != '');
        })
      ).subscribe();
    });
  };

  //isRefreshingToken$ = toObservable(this.#state.isRefreshingToken);
  //isAuthenticated$ = toObservable(this.isAuthenticatedSignal);


  /*  isAuthenticated = async () => {
      return await lastValueFrom(this.isAuthenticated$);
    };*/

  /*
    isAuthenticated = computed(() => {
      // TODO: some more checks to be added here
      return this.#state.accessToken() != '';
    });*/

  accessToken = computed(() => {
    return this.#state.accessToken();
  });

/*  persistRefreshToken = effect(() => {
    /!*    const state = this.#state();
        if (this.isAuthenticatedSignal()) {
          if (state.rememberMe) {
            console.log(`[AuthStore] - persisting authentication to permanent storage`, state);
            this.#storageHelper.save(this.LOCAL_STORAGE_KEY, this.#state.refreshToken());
            this.#storageHelper.removeFromSession(this.LOCAL_STORAGE_KEY);
          } else {
            console.log(`[AuthStore] - persisting authentication to session`, state);
            this.#storageHelper.saveToSession(this.LOCAL_STORAGE_KEY, this.#state.refreshToken());
            this.#storageHelper.remove(this.LOCAL_STORAGE_KEY);
          }
        } else {
          this.#storageHelper.removeFromSession(this.LOCAL_STORAGE_KEY);
          this.#storageHelper.remove(this.LOCAL_STORAGE_KEY);
        }*!/
  });*/

  setTokens = (accessToken: string, refreshToken: string, rememberMe: boolean) => {
    /*const accessTokenData = parseJwtAccesToken(accessToken);
    patchState(this.#state, {
      accessToken: accessToken,
      refreshToken: refreshToken,
      rememberMe: rememberMe,
      isRefreshingToken: false,
      user: {
        id: accessTokenData.nameid,
        name: accessTokenData.given_name || accessTokenData.email,
        email: accessTokenData.email,
        roles: accessTokenData.roles
      }
    });*/
  };

  getUser = () => {
    return this.#state.user;
  };

  logout = () => {
    this._oAuthService.logOut();
    this._oAuthService.revokeTokenAndLogout()
      .then(() => {
        patchState(this.#state, initialAuthState);
      }).catch(() => {
        patchState(this.#state, initialAuthState);
        this.#router.navigate(['/'], {
          onSameUrlNavigation: 'reload'
        }).finally(() => {
          window.location.reload();
        });
      });

  /*this.#router.navigate(['/'], {
    onSameUrlNavigation: 'reload'
  }).finally(() => {
    window.location.reload();
  });*/
};
/*
  login = (email: string, password: string, rememberMe: boolean): Promise<boolean> => {
    //this._oauthService.initLoginFlow();
    return new Promise<boolean>(async (resolve, reject) => {
      await lastValueFrom(this.#authenticationBackboneService.login({ email, password }).pipe(
        tapResponse({
          next: (response: LoginUserCommandResult) => {
            this.setTokens(response.token ?? '', response.refreshToken ?? '', rememberMe);
            resolve(true);
          },
          error: (err: HttpErrorResponse | null) => {
            console.error('[AuthStore] - Failed to resetPassword ', err);
            switch (err?.status ?? 0) {
              case 401:
                reject('Login failed, please try again');
                break;
              // TODO: other error messages
              default:
                reject('Something went wrong, please try again later');
                break;
            }
          }
        }))
      );
    });
  };*/

/*resetPassword = (email: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    await lastValueFrom(this.#authenticationBackboneService.resetPassword({ email }).pipe(
      tapResponse({
        next: () => {
          resolve(true);
        },
        error: (err: HttpErrorResponse | null) => {
          console.error('[AuthStore] - Failed to resetPassword ', err);
          switch (err?.status ?? 0) {
            /!*case 409:
              reject("Account already exists");
              break;*!/
            // TODO: other error messages
            default:
              reject('Something went wrong, please try again later');
              break;
          }
        }
      }))
    );
  });
};

confirmEmail = (email: string, token: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    await lastValueFrom(this.#authenticationBackboneService.confirmEmail({ email, token }).pipe(
      tapResponse({
        next: () => {
          resolve(true);
        },
        error: (err: HttpErrorResponse | null) => {
          console.error('[AuthStore] - Failed to confirm email ', err);
          switch (err?.status ?? 0) {
            /!*case 409:
              reject("Account already exists");
              break;*!/
            // TODO: other error messages
            default:
              reject('Something went wrong, please try again later');
              break;
          }
        }
      }))
    );
  });
};

verifyUserToken = (email: string, token: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    await lastValueFrom(this.#authenticationBackboneService.verifyUserToken(
        {
          email,
          tokenType: UserTokenType.ResetPassword,
          token
        }
      ).pipe(
        tapResponse({
          next: () => {
            resolve(true);
          },
          error: (err: HttpErrorResponse | null) => {
            console.error('[AuthStore] - Failed to verify UserToken ', err);
            switch (err?.status ?? 0) {
              case 400:
                reject('Invalid url token');
                break;
              // TODO: other error messages
              default:
                reject('Something went wrong, please try again later');
                break;
            }
          }
        }))
    );
  });
};
updatePasswordWithToken = (email: string, newPassword: string, token: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    await lastValueFrom(this.#authenticationBackboneService.updatePassword(
      {
        email,
        newPassword,
        token
      }).pipe(
      tapResponse({
        next: () => {
          resolve(true);
        },
        error: (err: HttpErrorResponse | null) => {
          console.error('[AuthStore] - Failed to updatePasswordWithToken ', err);
          switch (err?.status ?? 0) {
            case 400:
              reject('Invalid url token');
              break;
            // TODO: other error messages
            default:
              reject('Something went wrong, please try again later');
              break;
          }
        }
      }))
    );
  });
};

signUp = (email: string, password: string, name: string, languageCode: string, phoneNumber: string): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    await lastValueFrom(this.#authenticationBackboneService.createUser(
      {
        email,
        password,
        name,
        languageCode,
        phoneNumber
      }).pipe(
      tapResponse({
        next: () => {
          resolve(true);
        },
        error: (err: HttpErrorResponse | null) => {
          console.error('[AuthStore] - Failed to signUp ', err);
          switch (err?.status ?? 0) {
            case 409:
              reject('Account already exists');
              break;
            // TODO: other error messages
            default:
              reject('Something went wrong, please try again later');
              break;
          }
        }
      }))
    );
  });
};

refreshAccessToken = (): Promise<boolean> => {
  console.info('[AuthStore] - refreshing access token');
  patchState(this.#state, { isRefreshingToken: true });
  return new Promise<boolean>(async (resolve, reject) => {
    const refreshToken = this.#state.refreshToken();
    this.#authenticationBackboneService.refreshToken({ refreshToken }).pipe(
      tapResponse({
        next: (response: RefreshTokenCommandResult) => {
          console.info('[AuthStore] - access token refreshed successfully');
          this.setTokens(response.token ?? '', response.refreshToken ?? '', this.#state.rememberMe());
          patchState(this.#state, { isRefreshingToken: false });
          resolve(true);
        },
        error: (err: HttpErrorResponse | null) => {
          console.info('[AuthStore] - failed to refresh access token', err);
          patchState(this.#state, { isRefreshingToken: false });
          reject(err);
        }
      })
    ).subscribe();
  });
};*/


}

/*export const AuthStore_OLD = signalStore(
  { providedIn: 'root' },
  withState<AuthState>(initialAuthState),
  withComputed(({ accessToken }) => ({
    isAuthenticated: computed(() => {
      // TODO: some more checks to be added here
      return accessToken() != '';
    })
  })),
  withMethods(state => {
    return {
      setTokens: (accessToken: string, refreshToken: string) => {
        let accessTokenData = parseJwtAccesToken(accessToken);
        patchState(state, {
          accessToken: accessToken,
          refreshToken: refreshToken,
          isRefreshingToken: false,
          user: {
            id: accessTokenData.nameid,
            name: accessTokenData.given_name || accessTokenData.email,
            email: accessTokenData.email,
            roles: accessTokenData.roles
          }
        });
      },
      getUser: () => {
        return state.user;
      },
      logout: () => {
        patchState(state, initialAuthState);
      }
    };
  })
);*/

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStore } from '../auth.store';
import { map, take } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

/*export const authGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot) => {

    const authStore = inject(AuthStore);
    const router = inject(Router)

    authStore.isAuthenticated()
      .then((authenticated) => {
        if (authenticated) {
          router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then(r => {});
          return false
        }
        return true;
      })
      .catch(
        (err) => {
          router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then(r => {});
          return false;
        }
      );
  };*/


export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot,
                                               state: RouterStateSnapshot) => {

  const authStore = inject(AuthStore);

  //return true;
  return await authStore.isAuthenticated();
  /*  //return authStore.isAuthenticatedSignal();
    const oAuthService = inject(OAuthService);

    return oAuthService.


    const router = inject(Router)

    return authStore.isAuthenticated$.pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then(r => {});
          return false;
        }
        return true;
      }),
      take(1)
    );
    return true;*/
};

export type AuthGuardOptions = {
  allowedRoles: string[]
}

export const authGuard2 = (options: AuthGuardOptions) => {
  const guard: CanActivateFn = async (route: ActivatedRouteSnapshot,
                                      state: RouterStateSnapshot) => {

    const authStore = inject(AuthStore);
    const router = inject(Router);

    const authenticated = await authStore.isAuthenticated();

    if (!authenticated) {
      return false;
    }
    if (options.allowedRoles.length > 0) {
      const user = authStore.getUser();
      console.log("ROLES, ", user())
      if (!user().roles.some(role => options.allowedRoles.includes(role))) {
        router.navigate(['/']).then(r => {
        });
        return false;
      }
    }
    return true;
  };

  return guard;
};
